<template>
  <div class="main-block">
    <div class="main-block-title">Director or attorney</div>
    <div class="main-block-content">

      <ul v-if="registrationData.member.errors['DIRECTOR']" class="summary">
        <li class="error" v-for="error in registrationData.member.errors['DIRECTOR']" :key="error">{{ error }}</li>
      </ul>

      <person v-model="director" v-bind:errors.sync="errors"/>

      <div class="form-buttons text-right">
        <button data-test="submit" type="submit" class="btn-next" @click="submit">
          <span>Save and proceed</span>
          <icon-next color="#19191C" size="15"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Person from "@/components/Person";
import IconNext from "@/assets/icons/IconNext";
import {mapGetters, mapState} from "vuex";

const TYPE_DIRECTOR = 'DIRECTOR'

export default {
  name: 'Director',
  components: {
    Person,
    IconNext
  },
  data () {
    return {
      director: {
        birthday: '',
        middle_name: '',
        last_name: '',
        first_name: '',
        gender: '',
        citizenship_country_id: '',
        email: '',
        phone: '',
        is_pep: '',
        is_us: '',
        type: TYPE_DIRECTOR,
        country_id: '',
        city: '',
        street: '',
        postal_code: '',
        house: '',
        apartment: '',
        files: [],
        address_confirmation_files: []
      },

      errors: {}
    }
  },
  computed: {
    ...mapGetters(['getRegistrationDataPart']),
    ...mapState({
      registrationHash: state => state.registration.hash,
      registrationData: state => state.registration.data,

      // Default list data
      genderList: state => state.init.genders,
      boolAnswers: state => state.init.boolAnswers,
      typeList: state => state.init.memberTypes,
      countries: state => state.countries.list
    }),

    formData () {
      return {
        birthday: this.director.birthday,
        middle_name: this.director.middle_name,
        last_name: this.director.last_name,
        first_name: this.director.first_name,
        gender: this.director.gender,
        citizenship_country_id: this.director.citizenship_country_id,
        email: this.director.email,
        phone: this.director.phone,
        is_pep: this.director.is_pep,
        is_us: this.director.is_us,
        type: this.director.type,
        country_id: this.director.country_id,
        city: this.director.city,
        street: this.director.street,
        postal_code: this.director.postal_code,
        house: this.director.house,
        apartment: this.director.apartment,
        files: this.director.files,
        address_confirmation_files: this.director.address_confirmation_files
      }
    },
  },
  mounted() {
    const director = this.getRegistrationDataPart('member').items.find(item => {
      return item.attributes.type === TYPE_DIRECTOR
    })

    if (director) {
      this.director = director.attributes
    }
  },
  methods: {
    submit () {
      let promise = undefined

      if (this.director.id) {
        promise = this.$sdk.registration.member.update(this.registrationHash, this.director.id, this.formData, 0)
      } else {
        promise = this.$sdk.registration.member.create(this.registrationHash, this.formData, 0)
      }

      promise.then(response => {
        const data = response.data

        if (Array.isArray(data) && data.length === 0) {
          this.$store.dispatch('updateRegistration').then(() => {
            this.$emit('next-step')
          })
        } else {
          this.errors = response.data
        }
      })
    }
  },
}
</script>
